<template>
  <div id="app">
    <transition name="loader">
      <Loader v-if="isLoading" />
    </transition>
    <Header
      class="header_poll"
      :isGreen="isGreen"
      :isOpenMenu="isOpenMenu"
      :isMobile="isMobile"
      :getme="getme"
      :config="config"
      @toggle-menu="() => toggleMenu()"
      @open-login-popup="
        isOpenPopup = true;
        popupName = 'phone';
        isLoginAction = true;
      "
    />
    <Primary
      :acceptance="getme.acceptance"
      :acceptanceAutoPayment="getme.acceptanceAutoPayment"
      :acceptAd="acceptAdValue"
      :config="config"
      :getme="getme"
      :checkCookie="checkCookie"
      @request="request"
      @set-popup="setPopup"
      @acceptance-change="changeAception"
      @acceptance-ad="changeAd"
    />
    <Footer :config="config" :getme="getme" />
    <Popup
      v-if="isOpenPopup"
      :slotName="popupName"
      @close-popup="
        isOpenPopup = false;
        phoneNotFound = false;
      "
    >
      <template v-slot:phone>
        <PopupLogin
          :common="config.dictionary.common"
          :phoneNotFound="phoneNotFound"
          @request-login="requestLogin"
          @clear-field="clearField"
        />
      </template>
      <template v-slot:acceptance>
        <PopupAcceptance :common="config.dictionary.common" />
      </template>
      <template v-slot:sms>
        <PopupSMS
          :common="config.dictionary.common"
          :wrongCode="getme.wrongCode"
          @apply-sms="
            code => {
              isLoginAction
                ? applySmsQuestionLogin(code)
                : applySmsQuestion(code);
            }
          "
        />
      </template>
    </Popup>
  </div>
</template>

<script>
import Footer from '@/components/app/Footer';
import Header from '@/components/app/Header';
import Loader from '@/components/app/LoaderWithAnimation';
import Popup from '@/components/app/Popup';
import PopupAcceptance from '@/components/app/Popups/PopupAcceptance';
import PopupLogin from '@/components/app/Popups/PopupLogin';
import PopupSMS from '@/components/app/Popups/PopupSMS';
import Primary from './views/Primary';

import { changePath, getDefualtConfig, getmeDefault } from '@/utils/commonFuncs';
import { toggleFreez, toggleMenu, updateWindowWidth } from '@/utils/helperApp';
import doRequest from '@/utils/requests/doRequest';
import doRequestFrontConfig from '@/utils/requests/doRequestFrontConfig';
import {
  applySmsQuestionLogin,
  doRequestLogin
} from '@/utils/requests/doRequestLogin';
import Cookies from 'js-cookie';

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Popup,
    PopupAcceptance,
    PopupSMS,
    PopupLogin,
    Primary,
    Loader
  },
  data() {
    return {
      isGreen: true,
      isLoading: true,
      isOpenMenu: false,
      isOpenPopup: false,
      popupName: '',
      isLoginAction: false,
      phoneNotFound: false,
      widnowWidth: window.innerWidth,
      toggleMenu,
      updateWindowWidth,
      toggleFreez,
      doRequestFrontConfig,
      doRequest,
      doRequestLogin,
      applySmsQuestionLogin,
      phoneLogin: '',
      getme: {
        acceptance: false,
        acceptanceAutoPayment: false,
        daysCount: 'дней',
        ...getmeDefault
      },
      acceptAdValue: false,
      config: getDefualtConfig('primary')
    };
  },
  computed: {
    isMobile() {
      return this.widnowWidth <= 990;
    },
    checkCookie() {
      return (
        Cookies.get('sbg-cpa') !== undefined || Cookies.get('CPA') !== undefined
      );
    }
  },
  watch: {
    widnowWidth(value) {
      if (value > 990 && this.isOpenMenu) {
        this.isOpenMenu = false;
        this.toggleFreez(false);
      }
    },
    isLoading(value) {
      toggleFreez(value);
    },
    isOpenPopup(value) {
      toggleFreez(value);
    }
  },
  methods: {
    clearField() {
      this.phoneNotFound = false;
    },
    requestLogin(phone) {
      this.doRequestLogin(phone);
    },
    applySmsQuestion(code) {
      this.getme.contactData.code_hash = this.getme.code_hash;
      this.getme.contactData.code = code;

      // request
      this.doRequest(
        {
          contactData: this.getme.contactData,
          passportData: this.getme.passportData,
          csrf: this.getme.csrf,
          loanSum:
            Cookies.get('loanSum') !== undefined
              ? Cookies.get('loanSum')
              : this.getme.loanSum,
          loanTerm:
            Cookies.get('loanTerm') !== undefined
              ? Cookies.get('loanTerm')
              : this.getme.loanTerm
        },
        'contact'
      );
    },
    setPopup(name) {
      this.isOpenPopup = true;
      this.popupName = name;
    },
    afterRequestGetme() {
      // canvas
      this.isLoading = false;
    },
    request(data) {
      this.isLoginAction = false;

      this.getme.contactData.phone = data.phone;
      this.getme.contactData.email = data.email;

      if (!this.getme.acceptanceAutoPayment) {
        changePath('final')
        return;
      }

      this.doRequest(
        {
          contactData: this.getme.contactData,
          passportData: this.getme.passportData,
          csrf: this.getme.csrf,
          isAgreed4ad: this.acceptAdValue,
          loanSum:
            Cookies.get('loanSum') !== undefined
              ? Cookies.get('loanSum')
              : this.getme.loanSum,
          loanTerm:
            Cookies.get('loanTerm') !== undefined
              ? Cookies.get('loanTerm')
              : this.getme.loanTerm
        },
        'contact'
      );
    },
    changeAception({ target }) {
      this.getme[target.name] = target.checked;
    },
    changeAd({ target }) {
      this.acceptAdValue = target.checked;
    }
  },
  created() {
    window.addEventListener('resize', this.updateWindowWidth.bind(this));

    const _this = this;
    let checkCookies = setInterval(function() {
      if (
        Cookies.get('sbg-identity') != undefined ||
        Cookies.get('sbg-identity') != null
      ) {
        clearInterval(checkCookies);
        _this.doRequestFrontConfig('primary');
      }
    }, 300);
  }
};
</script>
